import styled from 'styled-components';

export const Wrapper = styled.header`
  position: absolute;
  top: 0vw;
  left: 0;
  width: 30vw;
  z-index: 9999;

  @media screen and (max-width: 900px) {
    left: auto;
    position: relative;
    top: auto;
    width: auto;
  }
`;

export const InnerWrapper = styled.div`
  position: ${(props) => (props.isSticky ? 'fixed' : 'absolute')};
  padding: 5vw;
  top: 0;
  width: 30vw;
  z-index: 5;

  @media screen and (max-width: 900px) {
    margin-bottom: 5.6rem;
    padding: 0;
    position: relative;
    top: auto;
    width: auto;
  }
`;

export const Intro = styled.div`
  a {
    background: none;
  }
`;
