import styled from 'styled-components';

export const Form = styled.form`
  margin-top: 1.4rem;
`;

export const Input = styled.input`
  border: #000 solid 1px;
  border-radius: 3px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  line-height: 1.4em;
  margin-right: 3px;
  padding: 3px 5px;
`;

export const Button = styled.input`
  background: #eee;
  border: #000 solid 1px;
  border-radius: 3px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  line-height: 1.4em;
  padding: 3px 8px;
`;
