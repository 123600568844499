import React, { useState } from 'react';

import { Form, Input, Button } from './style';

const NewsletterForm = () => {
  const [state, setState] = useState({
    EMAIL: '',
    b_72457b30656a69690ce365c0e_e39ff009c0: '',
  });

  const handleChange = (evt) => {
    setState({ ...state, [evt.target.name]: evt.target.value });
  };

  return (
    <Form
      action="https://sam-w.us16.list-manage.com/subscribe/post?u=72457b30656a69690ce365c0e&amp;id=e39ff009c0"
      method="post"
      name="mc-embedded-subscribe-form"
      target="_blank"
      novalidate
    >
      <Input
        type="email"
        value={state['EMAIL']}
        name="EMAIL"
        placeholder="Enter your email"
        onChange={handleChange}
      />
      <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
        <input
          type="text"
          name="b_72457b30656a69690ce365c0e_e39ff009c0"
          tabindex="-1"
          value={state['b_72457b30656a69690ce365c0e_e39ff009c0']}
          onChange={handleChange}
        />
      </div>
      <Button type="submit" value="Subscribe" name="subscribe" />
    </Form>
  );
};

export default NewsletterForm;
