import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'gatsby';

import NewsletterForm from '../NewsletterForm';

import { Wrapper, InnerWrapper, Intro } from './style';

const Header = ({ intro, withBackButton }) => {
  const [wrapperEl, setWrapperEl] = useState();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (!wrapperEl) return;

    const handleScroll = () => {
      const scrollTop = wrapperEl.getBoundingClientRect().top;
      setIsSticky(scrollTop <= 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [wrapperEl]);

  return (
    <Wrapper
      ref={(el) => {
        if (el && !wrapperEl) setWrapperEl(el);
      }}
    >
      <InnerWrapper isSticky={isSticky}>
        <Intro dangerouslySetInnerHTML={{ __html: intro }} />
        {withBackButton ? (
          <Link to="/" style={{ textDecoration: 'none' }}>
            Back
          </Link>
        ) : (
          <NewsletterForm />
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

export default Header;
